@import "./vars";
@import "./fonts";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #__next {
  font-family: 'opensans', sans-serif;
  height: 100%;
  color: $text-default-color;
}

// IE 11
@media all and (-ms-high-contrast:none){
 /*.foo { color: green } /* IE10 */
 *::-ms-backdrop, .img-fluid { width: 100%; } /* IE11 */
}

.screenblock {
  margin: 0; height: 100%; overflow: hidden
}

::-moz-selection {
  background: #32735F;
  color: #fff;
}
::selection {
  background: #32735F;
  color:  #fff;
}


// Stripe

.stripe-control {
  padding-top: 9px;
}


// Utilities

.blur {
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
}

.no-shadow {
  box-shadow: none;
}

.shadow-box {
  border: 1px solid #efefef;
  border-radius: 6px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034);
}
.shadow-box:hover {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048);
}

.pre-line {
  white-space: pre-line !important;
}

.debug-pre {
  overflow: scroll;
  width: 150px;
  max-height: 500px;
  font-size: 6px;
  position: fixed;
  right: 0;
  background: #eee;
}

.no-select {
  user-select: none;
}

.cursor {
  cursor: pointer;
}

.rounded-sm {
  border-radius: 4px !important;
}

.rounded-md {
  border-radius: 8px !important;
}

.rounded-lg {
  border-radius: 16px !important;
}



/* Fonts & texts */


.f1 {
  font-family: 'opensans', sans-serif;
  &.bold {
    font-family: 'opensans-bold';
  }
  &.sbold {
    font-family: 'opensans-semibold';
  }
}

.f2 {
  font-family: 'newyork';
  &.bold {
    font-family: 'newyork-bold';
  }
  &.sbold {
    font-family: 'newyork-semibold';
  }
}

.f3 {
  font-family: 'Gotham';
  font-style: normal;
  &.bold {
    font-weight: 600;
  }
}

.bg-light {
  background-color: $primary-light !important;
}
.bg-primary-light {
  background-color: $primary-light !important;
  color: $primary;
}
.bg-danger-light {
  background-color: $danger-light !important;
  color: $danger;
}

.text-lighter {
  color: $lighter !important;
}

.text-italic {
  font-style: italic;
}
.text-bold {
  font-weight: bold;
}

.text-surline {
  position: relative;
}
.text-surline:after {
  position: absolute;
  content: '';
  left: 0px;
  top: 24px;
  height: 18px;
  width: 100%;
  background-color: rgba(255, 143, 96, 0.2);
}

.xsmall {
  font-size: 10px;
}


/* Titles */

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'newyork-semibold';
  font-weight: 600;
  user-select: none;
  position: relative;
}

h1, .h1 {
  font-size: 32px;
  margin-bottom: 40px;
}

h2, .h2 {
  font-family: 'newyork';
  font-size: 26px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 18px;
  line-height: 150%;
}

@media (max-width: 767px) { // md down
  h1, .h1 {
    font-size: 24px;
    margin-bottom: 20px;

    .text-surline:after {
      top: 18px;
      height: 14px;
    }
  }

  h2, .h2 {
    font-family: 'newyork';
    font-size: 20px;
  }

  h3, .h3 {
    font-size: 18px;
  }

  h4, .h4 {
    font-size: 16px;
    line-height: 120%;
  }
}







/* Links & Btns */

a {
  color: $primary;
}

button {
  &:active, &:focus {
    outline: none;
  }
}

.btn {
  font-weight: bold;
  padding: 8px 20px;
  box-shadow: none;

  &:focus {
    box-shadow: none !important;
  }

  &.btn-xs {
    font-size: 10px;
    padding: 4px 10px;
  }

  &.btn-link {
    padding-left: 0;
    padding-right: 0;
  }

  &.btn-upload {
    @extend .btn-light;
    background-color: #EFF2F2 !important;

    color: $primary;
    border: 1px dashed $primary;
    padding: 20px;
    border-radius: $input-border-radius !important;

    &:active, &:hover, &:focus {
      color: $primary !important;
      border: 1px dashed $primary;
    }

    &.is-invalid {
      border: 1px dashed $danger !important;
      background: #fff !important;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
    }
  }

  &.btn-border {
    background-color: #fff !important;

    color: $primary;
    border: 1px solid $primary;
  }

  &.btn-primary-light {
    background-color: $primary-light !important;
    color: $primary;
    border: 1px solid $primary-light !important;

    &:active, &:hover, &:focus {
      color: $primary !important;
      border: 1px solid $primary !important;
    }
  }

  &.btn-primary-light {
    background-color: $primary-light !important;
    color: $primary;
    border: 1px solid $primary-light !important;

    &:active, &:hover, &:focus {
      color: $primary !important;
      border: 1px solid $primary !important;
    }
  }
}


.dropdown-item {
  cursor: pointer;
  &.active, &:active {
    background-color: #fff !important;
  }
}


/* Tabs */

.tabs li {
  border-bottom: 1px solid #dadada;
  flex: 1;
  padding: 20px 0;
  font-size: 14px;
}
.tabs li.active {
  color: #32735F;
  border-color: #32735F;
}

/* Cards */

.card {
  border: none;
  background: none;
  display: block;

  &> .card-body {
    padding: 30px 60px;
  }

  &> .card-footer {
    padding: 20px 60px;
    border: none;
    background: none;
  }
}


@media (max-width: 767px) { // md down
  .card {
    &> .card-body {
      padding: 20px !important;
    }

    &> .card-footer {
      padding: 20px !important;
    }
  }
}


/* Page Image */

// md down
@media (max-width: 767px) {

  .page-image {
    height: 80px;
    margin: 10px 20px;
    border-radius: 10px;
  }

}
// md up
@media (min-width: 768px) {
  .page-image {
    width: 100%;
    object-fit: cover;
    max-height: 250px;
  }
}

/* Forms */
.form {
  h3 {
    margin-top: 25px;
    margin-bottom: 15px;
  }
}

.form-control {
  color: $input-color !important;
  border-color: $input-border !important;
  border-radius: $input-border-radius !important;
  height: $input-height;

  &.radius-left-none {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
  }
  &.radius-right-none {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9E9E9E !important;
    opacity: 1; /* Firefox */
  }

  &:focus {
    box-shadow: none;
    /*border-color: #dec1a7;*/
  }

  &.is-invalid {
    border-color: $danger !important;
    background: none !important;
  }
}

.form-control-sm {
  height: auto !important;
  padding: 5px 10px;
  color: grey !important;
  border-color: #eeeeee !important;
}

.date-input[readonly] {
  background-color: #fff !important;
}

.MuiTextField-root {
  color: $input-color !important;
  border-color: $input-border !important;
  border-radius: $input-border-radius !important;
  height: $input-height;

  &.radius-left-none {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
  }
  &.radius-right-none {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9E9E9E !important;
    opacity: 1; /* Firefox */
  }

  &:focus {
    box-shadow: none;
    /*border-color: #dec1a7;*/
  }

  &.is-invalid {
    border-color: $danger !important;
    background: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

select {
  /* fiw for chrome for letting border radius take effect*/
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 99% 64%;
  background-repeat: no-repeat;
}

.input-group {
  &> input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &> .input-group-prepend {
    &> .btn {
      border-top-left-radius: $input-border-radius !important;
      border-bottom-left-radius: $input-border-radius !important;
    }
  }
}



/* Layout */

.layout {

  min-height: 100%;

  &.with-footer {
    display: flex;
    flex-direction: column;
  }

  .push-footer {
    flex: 1 0 auto;
  }

  .footer {
    flex-shrink: 0;
  }
}

/* List  */

.list-group-item {
  border: 1px solid #dadada;
  padding: 20px 0;
}

.list-group-item:last-child {
  border-bottom: 1px solid #dadada !important;
}


/* Badges & alerts */

.badge {
  padding: 6px 10px;
  border-radius: 15px;
  font-weight: normal;
}

.badge-light {
  color: $dark !important;
  background-color: $primary-light !important;
}

.alert {
  font-size: 14px;
}
.alert-danger-light {
  color: $danger !important;
  background-color: $danger-light !important;
}

.notice {
  font-size: 12px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  font-family: 'newyork-semibold';
  color: #6b6b6b;
}
.notice-danger {
  border-color: #ff8f6026;
  color: #FF8F60;
}

/* Segments */

.segment {
  color: $lighter;
  border-bottom: 1px solid #dadada;
  cursor: pointer;
  font-size: 14px;

  &.active {
    color: $primary;
    border-color: $primary;
  }

}

/* Snackbars */

.bg-none { background: none; }

.bg-snackbar-default {
  background-color: $light;
}

.bg-snackbar-default span {
  color: white;
}

.bg-snackbar-default button {
  color: $snackbar-cta;
}

.bg-snackbar-default button:hover {
  color: $primary;
}

.bg-snackbar-default .snackbar-dismiss {
  color: white;
}

.bg-error {
  background-color: $danger-light;
}

.bg-error span {
  color: $danger;
}

.bg-error button {
  color: $danger;
}

.bg-rounded {
  border-radius: 8px;
}

.bg-error .snackbar-dismiss {
  color: $danger;
}

.toasty-container div {
  border: 0px solid transparent !important;
}

.toasty-container > div {
  box-shadow: none !important;
}

.snackbar {
  max-width: 100%;
}

// md down
@media (max-width: 767px) {
  .toasty-container.bottom-left {
    bottom: 65px !important;
    max-width: 100% !important;
  }
}

// md up
@media (min-width: 768px) {
  .toasty-container.bottom-left {
    bottom: 50px !important;
    max-width: 50% !important;
  }


}

/* Beacons */

// md down
@media (max-width: 767px) {
  .BeaconContainer {
    height: calc(100% - 110px) !important;
    bottom: 80px !important;
  }
}
// md up
@media (min-width: 768px) {
  .BeaconContainer {
    height: calc(100% - 120px) !important;
    bottom: 120px !important;
    right: 30px !important;
  }

  .BeaconFabButtonFrame {
    bottom: 50px !important;
  }
}

@import "./addons/inputs";
@import "./addons/toaster";


/* Misc */
/* ft-letter */

.mode-container .mode ~ .mode-options {
  border: 2px solid #c8dfe0;
  padding: 20px;
  margin-bottom: -2px;
}

/* block-intro */

.intro {
  font-size: 24px;
}

.intro:first-letter {
  font-family: 'newyork-bold';
  float: left;
  font-size: 78px;
  line-height: 72px;
  padding-top: 0px;
  padding-right: 10px;
}

// md down
@media (max-width: 767px) {
  .intro {
    font-size: 20px;
    line-height: 28px;
  }

  .intro:first-letter {
    font-family: 'newyork-bold';
    float: left;
    font-size: 60px;
    line-height: 50px;
    padding-top: 4px;
    padding-right: 10px;
  }
}